import { useContext } from 'react';
import cn from 'classnames';

import { StateContext } from '@/context/index';
import { getOriginSiteUrl } from '@/utils/helper';

import { CtaWrapper } from '@/components/molecules/CTA';
import WestholmeLogo from '@/components/atoms/Logo/WestholmeNewLogo';
import DDLogo from '@/components/atoms/Logo/DDLogo';
import AACoLogo from '@/components/atoms/Logo/AACoLogo';
import AACo1824Logo from '@/components/atoms/Logo/AAco1824Logo';

function Footer() {
  const { brand } = useContext(StateContext);

  const getHomeUrl = () => {
    return getOriginSiteUrl(brand);
  };

  const getFaqUrl = () => {
    return `${getOriginSiteUrl(brand)}${brand === '1824' ? '' : 'faq'}`;
  };
  const getAboutUrl = () => {
    return `${getOriginSiteUrl(brand)}${brand === '1824' ? '' : 'about-us'}`;
  };

  const getContactUrl = () => {
    if (['wh'].includes(brand) || !brand) {
      return 'https://www.westholme.com/general-enquiry';
    }
    return `${getOriginSiteUrl(brand)}${
      ['dd'].includes(brand) ? 'contact' : 'contact-us'
    }`;
  };

  const contactUsUrl = getContactUrl();

  return (
    <div className="aaco-dp-footer flex flex-col gap-y-[54px] 1824:gap-y-[30px] 1824:md:gap-0 justify-center items-center pb-[45px] md:pb-[86px] bg-acacia aaco:bg-aaco-blue dd-en:bg-progressive-blue 1824:bg-rust 1824:pb-0">
      <div className="aaco-dp-footer__border-top w-full h-1 dd-en:h-[5px] bg-transparent dd-en:bg-quality-gold mb-[44px] md:mb-[67px] 1824:mb-[52.5px] 1824:md:mb-[70px] 1824:bg-transparent"></div>
      <div
        className={cn(
          'flex flex-col md:flex-row',
          'gap-y-[20px] md:gap-x-[40px]',
          'font-text text-[11px] tracking-wider text-center  ',
          'text-white aaco:text-aaco-gold 1824:text-cream aaco:text-button aaco:font-text-roboto aaco:font-button',
          ' uppercase dd-en:normal-case'
        )}
      >
        <a href={getHomeUrl()}>Home</a>
        <a href={contactUsUrl}>Contact</a>
        {['wh', 'dd', '1824'].includes(brand) ? (
          <a href={getFaqUrl()}>FAQ</a>
        ) : (
          <a href={getAboutUrl()}>About us</a>
        )}
      </div>
      <CtaWrapper page="/" classNames="hidden aaco:block dd-en:hidden">
        <AACoLogo />
      </CtaWrapper>
      <CtaWrapper
        page="/"
        classNames="block aaco:hidden dd-en:hidden 1824:hidden"
      >
        <WestholmeLogo width={126} height={15} color="#fff" />
      </CtaWrapper>
      <CtaWrapper page="/" classNames="hidden dd-en:block">
        <DDLogo width={100} height={116} />
      </CtaWrapper>
      <CtaWrapper
        page="/"
        classNames="hidden 1824:block 1824:mt-[30px] 1824:mb-[38.5px] 1824:md:mb-[50px]"
      >
        <AACo1824Logo classNames="w-[145px] md:w-[133px] h-[120px] md:h-[110px]" />
      </CtaWrapper>
    </div>
  );
}

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;
