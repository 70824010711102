import { useContext } from 'react';
import cn from 'classnames';

import { StateContext } from '@/context/index';

import { getOriginSiteUrl } from '@/utils/helper';

import DDLogo from '@/components/atoms/Logo/DDLogo';
import WestholmeLogo from '@/components/atoms/Logo/WestholmeNewLogo';
import { CtaWrapper } from '@/components/molecules/CTA';
import AACoLogo from '@/components/atoms/Logo/AACoLogo';
import AACo1824Logo from '@/components/atoms/Logo/AAco1824Logo';

function Header({ isTransparent = false }) {
  const { brand } = useContext(StateContext);

  const getContactUrl = () => {
    if (['wh'].includes(brand) || !brand) {
      return 'https://www.westholme.com/general-enquiry';
    }
    return `${getOriginSiteUrl(brand)}${
      ['dd'].includes(brand) ? 'contact' : 'contact-us'
    }`;
  };

  const contactUsUrl = getContactUrl();

  const isWH = brand === 'wh';
  const is1824 = brand === '1824';

  return (
    <div
      className={cn(
        'aaco-dp-header',
        'sticky z-50 top-0 w-full',
        'bg-white dd-en:bg-progressive-blue dd-en:lg:shadow-dropdown'
      )}
    >
      <div
        className={cn(
          'aaco-dp-header__main-nav',
          'h-20 1824:h-[55px] 1824:lg:h-20',
          'px-[14px] 1824:px-[30px] lg:px-10 pb-5 1824:pb-0 lg:pb-0 1824:lg:px-[93px]',
          'flex justify-between lg:items-center',

          {
            'bg-white lg:bg-transparent': isTransparent,
            '1824:bg-rust': !isTransparent,
            'items-center': isWH,
          }
        )}
      >
        <div
          className={cn({
            'hidden lg:flex lg:flex-1': isWH,
            'flex flex-1': !isWH,
          })}
        ></div>
        <div
          className={cn(
            'aaco-dp-header__logo',
            `flex-1 flex justify-center`,
            'pt-4 en-dd:pt-10 ml-[-30px] 1824:ml-0 lg:ml-0',
            {
              'absolute top-[-13px] lg:top-[-20px] left-[calc(50vw_-_63px)] lg:left-[calc(50vw_-_130px)]':
                is1824,
            },
            'lg:pt-0'
          )}
        >
          <CtaWrapper url={getOriginSiteUrl(brand)}>
            {brand === 'aaco' && <AACoLogo />}
            {brand === 'wh' && (
              <WestholmeLogo width={126} height={15} color="#3B3727" />
            )}
            {brand === 'dd' && <DDLogo width={51.05} height={60} />}
            {brand === '1824' && (
              <AACo1824Logo
                classNames="w-[126px] lg:w-[260px] h-[104px] lg:h-[200px]"
                alt="Logo for 1824"
              />
            )}
          </CtaWrapper>
        </div>
        <div
          className={cn(
            'aaco-dp-header__main-nav__right',
            'flex-1 flex justify-end',
            'gap-x-3 pt-4 1824:pt-0 lg:pt-0'
          )}
        >
          <CtaWrapper
            url={contactUsUrl}
            classNames={cn(
              'aaco-dp-header__main-nav__right__contact-us',
              'aaco-cta link-invert cursor-pointer',
              'aaco:hidden lg:flex aaco:lg:hidden justify-center items-center justify-self-end',
              'h-10 1824:h-auto 1824:lg:h-10 lg:w-15 w-max',
              'px-5 dd-en:px-4 1824:px-0 1824:lg:px-5',
              'rounded-3xl dd-en:rounded-[100px]',

              'font-text font-body-bold text-[12px]',
              'dd-en:font-sans dd-en:font-button dd-en:text-button dd-en:text-[12px] dd-en:lg:text-[14px]',
              '1824:font-text 1824:font-button 1824:text-button',

              'text-acacia dd-en:text-white 1824:text-white 1824:lg:text-rust',
              'bg-warm-grey dd-en:bg-actual-dd-gold 1824:bg-transparent lg:1824:bg-white ',

              {
                'font-roboto': isWH,
              }
            )}
          >
            CONTACT US
          </CtaWrapper>
        </div>
      </div>
    </div>
  );
}

Header.propTypes = {};

Header.defaultProps = {};

export default Header;
