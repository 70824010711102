import PropTypes from 'prop-types';

import { getButtonClass } from './Button.constants';

function Button({ children, invert, style, onClick, role, type, tabIndex }) {
  const buttonClass = getButtonClass({
    type,
    invert,
    style,
  });
  return (
    <button
      className={buttonClass}
      onClick={onClick}
      role={role}
      tabIndex={tabIndex}
    >
      {children}
    </button>
  );
}

Button.propTypes = {
  children: PropTypes.node,
  invert: PropTypes.bool,
  onClick: PropTypes.func,
  role: PropTypes.string,
  tabIndex: PropTypes.number,
  // button's classname
  style: PropTypes.string,
  type: PropTypes.string,
};

Button.defaultProps = {
  children: null,
  invert: false,
  role: 'button',
  style: '',
  type: 'primary',
};

export default Button;
