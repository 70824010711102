import { useState, useContext, useEffect } from 'react';
import { useRouter } from 'next/router';
import cn from 'classnames';

import { DispatchContext, StateContext } from '@/context/index';

import {
  apiBaseUrl,
  isDDBrand,
  is1824Brand,
  isWHBrand,
  getOriginSiteUrl,
} from '@/utils/helper';

import Header from '@/components/organisms/_DistributorPortal/Header/LoginHeader';
import Button from '@/components/atoms/Button/Button';
import Footer from '@/components/organisms/_DistributorPortal/Footer/LoginFooter';
import GeneralLayout from '@/components/layout/GeneralLayout';

import { LOGIN_BANNER } from '@/utils/constants';

export default function Home() {
  const [email, setEmail] = useState('');
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [formSubmitLoading, setFormSubmitLoading] = useState(false);
  const [loginLoading, setLoginLoading] = useState(false);
  const { user, brand } = useContext(StateContext);
  const dispatch = useContext(DispatchContext);
  const { push, query } = useRouter();

  const handleLoginFormSubmit = (e) => {
    e.preventDefault();

    setFormSubmitLoading(true);

    fetch(`${apiBaseUrl()}/api/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.status);
        }
        return response.json();
      })
      .then((data) => {
        setInvalidEmail(false);
        setLoginLoading(true);
        const { email, accessLevel, token } = data;
        sessionStorage.setItem('token', token);
        dispatch({
          type: 'SET_USER',
          payload: {
            email,
            accessLevel,
          },
        });
        if (accessLevel?.hasMultiple) push(`/portal/${brand}`);
      })
      .catch((error) => {
        if (error.message === '401') {
          setInvalidEmail(true);
        }
      })
      .finally(() => {
        setFormSubmitLoading(false);
      });
  };

  //once user context is set, redirect to brand-selector page
  useEffect(() => {
    if (user && user?.accessLevel) {
      if (user?.accessLevel?.hasMultiple) {
        push('/brand-selector');
      } else {
        push(`/portal/${brand}`);
      }
    }
  }, [push, user]);

  //check if there is query string to indicate which theme to load
  useEffect(() => {
    if (query.brand) {
      dispatch({
        type: 'SET_BRAND',
        payload: query.brand ?? 'wh',
      });
    }
  }, [dispatch, query]);

  const getContactUrl = () => {
    if (['wh'].includes(brand) || !brand) {
      return 'https://www.westholme.com/general-enquiry';
    }
    return `${getOriginSiteUrl(brand)}${
      ['dd'].includes(brand) ? 'contact' : 'contact-us'
    }`;
  };

  const contactUsUrl = getContactUrl();

  if (loginLoading) return null;

  const isDD = isDDBrand(query?.brand);
  const isWH = isWHBrand(query?.brand);
  const is1824 = is1824Brand(query?.brand);

  return (
    <GeneralLayout brand={brand || query?.brand}>
      <Header />
      <div className="aaco-login w-full h-full dd-en:bg-progressive-blue 1824:bg-cream">
        <div className="container mx-auto 1824:px-[30px] pt-[58px] 1824:pt-[76px] 1824:lg:pt-[160px] pb-[139px] 1824:pb-9 1824:lg:pb-[200px]">
          <div className="flex flex-wrap">
            <div className="w-full lg:w-1/2 mb-4 lg:mb-0">
              <h1
                className={cn(
                  'default:text-[68px] default:lg:text-[120px] font-h1-mobile lg:font-h1 text-h1-mobile lg:text-h1 aaco:font-h1-mobile-aaco aaco:lg:font-h1-aaco aaco:text-h1-mobile-aaco aaco:lg:text-h1-aaco aaco:font-text-roboto aaco:text-aaco-blue ',
                  {
                    'dd-en:font-heading dd-en:font-h1-mobile lg:dd-en:font-h1 dd-en:text-white dd-en:uppercase':
                      isDD,
                    'font-heading-wh text-acacia ': isWH,
                    'font-heading text-m-h1 lg:text-h1 text-off-black uppercase':
                      is1824,
                  }
                )}
              >
                Welcome
              </h1>
              <span
                className={cn(
                  'mt-[50px] lg:mt-12',
                  '1824:mt-9 lg:1824:mt-[50px]',

                  'font-heading-wh font-h6-mobile lg:font-h6 text-h6-mobile lg:text-h6-mobile aaco:font-text-roboto aaco:text-aaco-grey aaco:text-[20px] inline-block',
                  'dd-en:font-text dd-en:font-body dd-en:text-body-large ',
                  '1824:font-text 1824:font-body 1824:text-body',

                  {
                    'font-heading-wh ': isWH,
                    'text-grey-6': isWH,
                  },
                  'text-eucalyptus-dark lg:text-grey-6 dd-en:text-white 1824:text-charcoal'
                )}
              >
                {isWH
                  ? `We produce Nature-Led Australian Wagyu—a practice of letting the land lead to produce premium Wagyu with a flavour that could only come from here. Our distributor portal is a collection of brand guidelines, image and video assets, collateral, and product information designed to make the work of sharing the Westholme brand efficient and cohesive. `
                  : `Step into our brand distributor portal, your hub for all brand
                guidelines, ready-to-use collateral in digital and print
                formats, high-quality images, videos, detailed product
                specifications, and enriching tutorials.`}
              </span>
            </div>
            <div className="w-full lg:w-1/2 lg:pl-20">
              <span
                className={cn(
                  'inline-block',
                  'text-dark-warm-grey lg:text-grey-6 aaco:text-aaco-grey dd-en:text-white 1824:text-charcoal',
                  'font-heading-wh font-h6-mobile lg:font-h6 text-h6-mobile lg:text-h6-mobile aaco:font-text-roboto',
                  '1824:font-text 1824:font-body 1824:text-body',
                  'aaco:text-[20px] dd-en:font-text dd-en:font-body dd-en:text-body-large',
                  {
                    'font-heading-wh text-grey-6': isWH,
                  }
                )}
              >
                To access our Distributor Portal, please input your registered
                email address.
                <br />
                If you&apos;re not yet registered, please{' '}
                <a
                  href={contactUsUrl}
                  target="_blank"
                  rel="noreferrer"
                  className="underline"
                >
                  contact us
                </a>{' '}
                with an access request.
                <br />
                Once verified, you&apos;ll be all set to dive into the portal.
              </span>
            </div>
          </div>
          <div className="flex flex-wrap mt-[62px] 1824:mt-[36px] 1824:lg:mt-20 flex-col-reverse 1824:flex-col lg:flex-row 1824:lg:flex-row">
            <div className="w-full lg:w-1/2">
              <span className="dd-en:block dd-en:font-text dd-en:font-body dd-en:text-body text-white">
                <span
                  className={cn(
                    'dd-en:font-text text-[11px] uppercase  dd-en:font-small-bold dd-en:text-small-bold',
                    'aaco:font-text-roboto aaco:text-tag aaco:font-tag',
                    '1824:font-heading 1824:text-m-h4 1824:font-m-h4 lg:1824:text-h4 lg:1824:font-h4',
                    'aaco:text-aaco-blue dd-en:text-actual-dd-gold 1824:text-rust',

                    {
                      'text-acacia font-roboto font-[500]': isWH,
                    }
                  )}
                >
                  {isDD || is1824 ? 'General Enquiries' : 'NEED ASSISTANCE?'}
                </span>
                <br />
                <div
                  className={cn(
                    'font-sans font-body text-body',
                    'dd-en:text-body dd-en:font-text',
                    '1824:font-text 1824:text-body 1824:font-body',
                    'text-dark-warm-grey aaco:text-aaco-grey dd-en:text-white 1824:text-charcoal',
                    '1824:mt-[7px] 1824:lg:mt-2.5',
                    {
                      'text-grey-6': isWH,
                    }
                  )}
                >
                  Please Contact{' '}
                  {brand === 'dd'
                    ? 'Darling Downs Reception on'
                    : brand === 'wh'
                    ? 'Westholme Reception on'
                    : 'Reception on'}
                  <br />
                  {brand === 'dd' ? '+61 7 3368 4400' : '+61 7 3368 4400'}
                </div>
              </span>
            </div>
            <div className="w-full lg:w-1/2 lg:pl-20 mb-[57px] lg:mb-0 1824:px-[22.5px] 1824:lg:pl-20 1824:pt-9 1824:lg:pt-0 1824:mb-0">
              <form method="POST" onSubmit={handleLoginFormSubmit}>
                <span className="dd-en:font-text dd-en:font-body dd-en:text-body text-white">
                  <span
                    className={cn(
                      'font-text text-[11px] aaco:font-text-roboto aaco:text-tag aaco:font-tag  dd-en:font-small-bold dd-en:text-small-bold',
                      'uppercase dd-en:normal-case 1824:normal-case',
                      'aaco:text-aaco-blue text-acacia 1824:text-rust dd-en:text-actual-dd-gold',
                      '1824:font-text 1824:text-small-bold 1824:font-semibold',
                      {
                        'text-acacia font-roboto font-500': isWH,
                        'tracking-widest ': !isWH,
                      }
                    )}
                  >
                    Email Address
                  </span>
                  <br />
                  <input
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="john.doe@example.com"
                    className={cn(
                      `w-full outline-none font-text font-body text-body normal-case text-black aaco:font-text-roboto aaco:font-body aaco:text-aaco-grey dd-en:text-white bg-transparent`,

                      '1824:text-small 1824:font-small',
                      '1824:placeholder:text-charcoal',

                      'pb-[9px] 1824:pb-[7px]',
                      'border-b-[2px]',
                      'dd-en:border-actual-dd-gold',
                      '1824:border-off-black',
                      {
                        'border-gum-blossom aaco:border-aaco-red-error dd-en:border-light-grey-2':
                          invalidEmail,
                        'border-[#838D75] aaco:border-aaco-blue dd-en:border-actual-dd-gold':
                          !invalidEmail,
                      }
                    )}
                    type={'email'}
                    tabIndex={1}
                    required
                  />
                  {invalidEmail && (
                    <span className="inline-block mt-2 text-gum-blossom text-[10px] dd-en:text-[12px] aaco:font-text-wh font-body aaco:text-aaco-red-error dd-en:text-white">
                      Oops! Your email is not recognized. To gain access, please{' '}
                      <a
                        className="cursor-pointer underline"
                        href={contactUsUrl}
                        target="_blank"
                        rel="noreferrer"
                      >
                        contact us
                      </a>
                      . Thank you for your patience.
                    </span>
                  )}
                  <Button
                    style={cn(
                      'aaco-cta link-invert cursor-pointer',
                      'rounded-button',
                      'flex items-center',
                      'w-fit h-10',
                      'px-10 1824:px-5',
                      'mt-[34px] 1824:mt-[30px]',
                      {
                        'pointer-events-none opacity-50': formSubmitLoading,
                      },
                      'font-body-bold text-[12px] aaco:font-text-roboto aaco:text-tag aaco:font-tag font-sans font-subheading text-button',
                      '1824:font-button 1824:text-button',
                      {
                        'font-[500]': isWH,
                      },
                      '',
                      'bg-acacia  aaco:bg-aaco-blue dd-en:bg-actual-dd-gold 1824:bg-rust'
                    )}
                    tabIndex={2}
                    type={'submit'}
                  >
                    ENTER
                  </Button>
                </span>
              </form>
            </div>
          </div>
          <img
            src={LOGIN_BANNER[brand] || LOGIN_BANNER.default}
            alt="Distributor Portal"
            className="mt-[50px] 1824:mt-[69px] lg:mt-[123px] 1824:lg:mt-[123px] dd-en:rounded-xl"
          />
        </div>
      </div>
      <Footer />
    </GeneralLayout>
  );
}
