import Head from 'next/head';

import { METAS } from '@/utils/constants';

export default function GeneralLayout({
  children,
  brand,
  title = 'Distributor Portal',
}) {
  const BRAND_METAS = METAS[brand] || METAS.aaco;
  return (
    <div className="flex flex-col lg:min-h-screen">
      <Head>
        <link
          rel="shortcut icon"
          type="image/x-icon"
          href={BRAND_METAS.shortcut}
          sizes="any"
        />
        <link
          rel="apple-touch-icon"
          type="image/png"
          sizes="180x180"
          href={BRAND_METAS.appleTouch}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href={BRAND_METAS.icon32}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href={BRAND_METAS.icon16}
        />
        <link rel="icon" href={BRAND_METAS.icon} sizes="any" />
        <link rel="manifest" href={BRAND_METAS.manifest} />
        <title>{title}</title>
      </Head>
      {children}
    </div>
  );
}
